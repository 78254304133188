import { RedirectWebMerchantFooter } from '@sweb-front/components';
import { COOKIES, MENTIONSLEGALES, WEBAPAGE_TITLES } from '@vat/configuration';
import { getDataBySetting, useAppOptions, useEngineLoader } from '@vat/utils';
import { Trans, useTranslation } from 'react-i18next';
import {
  FooterContainer,
  FooterStyledLink,
  FooterStyledNativeLink,
  FooterStyledParagraph,
  FooterStyledSpan,
  FooterSeparator,
} from './styles';
import { Fragment, useState } from 'react';
import { ModalLeave } from '@vat/layouts';
import { trackEvent } from '@sweb-front/utils';
import { useLocation } from 'react-router-dom';
import { IStep, selectSteps, useAppSelector } from '@sweb-front/store';
import config from 'src/utils/hooks/engineContext';

export type FooterProps = {
  opposedCommercialOffers?: boolean;
  canDirectlyShowCmp: boolean;
  urlPath?: string;
};

const Footer = ({
  opposedCommercialOffers = true,
  canDirectlyShowCmp = false,
}) => {
  const { t } = useTranslation();
  const { appOptions } = useAppOptions();
  const [showModalLeave, setShowModalLeave] = useState(false);
  const companyContext = useEngineLoader();
  const companyConfig = getDataBySetting(config, companyContext);

  const location = useLocation();
  const steps = useAppSelector(selectSteps);
  const defaultPathname = (steps ?? ([] as IStep[]))
    .find((st) => st.paths.includes('/'))
    ?.paths.filter((path) => path !== '/');

  const toogleModal = () => {
    if (!showModalLeave) {
      trackEvent({
        event: 'popinDisplay',
        modaleName: `E-Commerce : ${
          WEBAPAGE_TITLES[
            location.pathname === '/' ? defaultPathname?.[0] : location.pathname
          ] ?? 'No Page matching'
        } : Retour`,
      });
    }
    setShowModalLeave((prev) => !prev);
  };

  return appOptions.isMounted || canDirectlyShowCmp ? (
    <>
      <FooterContainer id="footer">
        <FooterSeparator />
        {!canDirectlyShowCmp ? (
          <RedirectWebMerchantFooter
            text={t('footer.backToWebMerchant')}
            onClick={toogleModal}
          />
        ) : (
          ''
        )}

        <FooterStyledLink
          role="link"
          target="_blank"
          rel="noopener noreferrer"
          to={`${MENTIONSLEGALES}`}
        >
          {t('footer.legalMentions')}
        </FooterStyledLink>
        <FooterStyledLink role="link" to={`${COOKIES}`}>
          {t('footer.cookies')}
        </FooterStyledLink>
        <div>
          <FooterStyledSpan>
            {t('footer.informationProcessing')}
          </FooterStyledSpan>
          <p> </p>
          <FooterStyledParagraph>
            {
              <Trans
                i18nKey="footer.paragraph.firstPart"
                components={{
                  company: <span>{companyConfig.value.company}</span>,
                }}
              />
            }
            {(
              companyConfig.value.footer.contact as Record<string, unknown>[]
            ).map((item) => (
              <Fragment
                key={`mail-${((item.mail ?? '') as string).replace(
                  /^[^a-zA-z0-9\-_]+$/,
                  ''
                )}`}
              >
                {` ${item.address}`}
                <FooterStyledNativeLink
                  href={`mailto:${item.mail}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.mail as string}
                </FooterStyledNativeLink>{' '}
              </Fragment>
            ))}
            {t('footer.paragraph.secondPart')}{' '}
            <FooterStyledNativeLink
              role="link"
              href={
                companyConfig.value.footer.personalDataProtectionLink as string
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('footer.paragraph.dataSecurityPolicy')}
            </FooterStyledNativeLink>
            .
          </FooterStyledParagraph>
        </div>
        {!opposedCommercialOffers ? (
          <FooterStyledParagraph data-testid="commercial-offers-paragraph">
            {t('footer.commercialOffers.firstPart')}{' '}
            <FooterStyledNativeLink
              role="link"
              href="https://www.bloctel.gouv.fr"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.bloctel.gouv.fr
            </FooterStyledNativeLink>
            . {t('footer.commercialOffers.secondPart')}
          </FooterStyledParagraph>
        ) : (
          <></>
        )}
      </FooterContainer>
      {showModalLeave && (
        <ModalLeave
          title={t('LeavePage.title')}
          description={t('LeavePage.description')}
          descriptionProgress={t('LeavePage.descriptionProgress')}
          confirmation={t('LeavePage.confirmation')}
          close={toogleModal}
        />
      )}
    </>
  ) : (
    <></>
  );
};

export default Footer;
