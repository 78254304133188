import React, { useEffect, useState } from 'react';
import { LogoBlock, ImgWrapper, PartnerLogo } from './styles';
import { getDataBySetting, useEngineLoader } from '@vat/utils';
import config from 'src/utils/hooks/engineContext';

export type LogoProps = {
  numVendor: string;
};

const checkImagePartnerExists = async (imagePath: string): Promise<boolean> => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = imagePath;
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
  });
};

const Logo: React.FC<LogoProps> = ({ numVendor }: LogoProps) => {
  const [showPartenaire, setShowPartenaire] = useState<boolean>(false);
  const companyCd = useEngineLoader();
  const companyData = getDataBySetting(config, companyCd);

  useEffect(() => {
    checkImagePartnerExists(`logos/${numVendor}.png`).then(setShowPartenaire);
  }, []);

  return (
    <LogoBlock>
      <ImgWrapper
        loading="lazy"
        src={companyData.value.logo.src}
        alt={`${companyData.value.logo.src}`}
        width={companyData.value.logo.width}
      />
      {showPartenaire && (
        <PartnerLogo
          loading="lazy"
          alt="LogoPartner"
          src={`logos/${numVendor}.png`}
        />
      )}
    </LogoBlock>
  );
};
export default Logo;
