import { MainSectionWrapper, Title } from './styles';
import { getDataBySetting, useEngineLoader } from '@vat/utils';
import config from 'src/utils/hooks/engineContext';
import MentionsLegales100 from './MentionsLegales100';
import MentionsLegales140 from './MentionsLegales140';
import CustomMentionsLegales from './CustomMentionsLegales';

export interface IMentionLegaleProps {
  companyCode?: number;
}

const MentionsLegales = ({ companyCode }: IMentionLegaleProps) => {
  let code = useEngineLoader();
  // By default we'll choose company 100 to display
  if (companyCode) {
    code = `${companyCode}`;
  }
  const companyConfig = getDataBySetting(config, code);

  return (
    <MainSectionWrapper id="mention-legales">
      <Title>Mentions Légales</Title>
      {code === '100' && <MentionsLegales100 />}
      {code === '140' && <MentionsLegales140 />}
      {!['100', '140'].includes(code) && (
        <CustomMentionsLegales data={companyConfig?.value?.legalNotices} />
      )}
    </MainSectionWrapper>
  );
};

export default MentionsLegales;
