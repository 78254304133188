import { ReactElement, ReactNode } from 'react';
import {
  CheckboxContainer,
  StyledCheckboxInput,
  StyledCheckboxLabel,
} from './styles';

export type CookiesCheckboxProps = {
  id: string;
  name: string;
  label?: ReactNode;
  labelWithElement?: ReactElement;
  state: boolean;
  onChange: (value: boolean) => void;
};

const CookiesCheckbox = ({
  label,
  labelWithElement,
  state,
  id,
  name,
  onChange,
}: CookiesCheckboxProps) => {
  const onInputhange = () => {
    onChange(!state);
  };
  return (
    <CheckboxContainer>
      <StyledCheckboxInput
        type="checkbox"
        checked={state}
        id={id}
        name={name}
        onChange={onInputhange}
      />
      <StyledCheckboxLabel htmlFor={id}>
        {label}
        {labelWithElement}
      </StyledCheckboxLabel>
    </CheckboxContainer>
  );
};

export default CookiesCheckbox;
