import { CONTEXT_ECONTRAT } from '@sweb-front/services';
import { IRoutingContext } from '@sweb-front/types';
import {
  trackEvent,
  trackCustomAction,
  trackLeavingPage,
} from '@sweb-front/utils';
import { IFinancialDetails } from '@sweb-front/types';

import { useCallback, useContext, useEffect } from 'react';
import { setIdHashed } from '../../containers/forms/utils';
import { PageLoadingContext, useWebAnalayseTracker } from '@vat/utils';

const useApprovedPayment = (opportunity, parameters, navigation) => {
  const { routingContexts } = navigation;
  const updatePageIsLoading = useContext(PageLoadingContext);

  const onContractClick = useCallback(() => {
    if (Array.isArray(routingContexts)) {
      const linkContract: IRoutingContext | undefined = routingContexts?.find(
        (routingContext: IRoutingContext) =>
          routingContext.contextUse === CONTEXT_ECONTRAT
      );

      trackEvent({
        event: 'interaction_formulaire',
        FormInteraction: 'Clic espace Contrat',
      });

      if (linkContract) {
        trackCustomAction('le client a cliqué sur le bouton espace contrat');
        window.open(linkContract.url, '_blank');
      }
    }
  }, [routingContexts]);

  let financialDetails = {} as IFinancialDetails;
  if (opportunity && opportunity.offers?.length > 0) {
    financialDetails = opportunity.offers[0].loans?.[0]?.financialDetails;
  }

  useWebAnalayseTracker({
    event: 'module_interaction',
    pageName: 'E-Commerce : FV',
    site: 'Ecommerce',
    workflow: parameters?.wayCd,
    // eslint-disable-next-line no-underscore-dangle
    environment: (window as unknown as WindowWithEnv)._env_?.env,
    visitorStatus: 'non-logged',
    Amount: (financialDetails?.overdraftAmt as number) ?? undefined,
    Rate: (financialDetails?.tncRt as number) ?? undefined,
    Term: (financialDetails?.term as number) ?? undefined,
    MonthlyPayment:
      (financialDetails?.monthlyPaymentWithoutInsuranceAmt as number) ??
      undefined,
    opportunityIdHashed: setIdHashed(opportunity),
    ContributorCode: Number(opportunity.distributor?.distributorNb ?? ''),
  });

  useEffect(() => {
    updatePageIsLoading(false);
    trackLeavingPage(() => trackCustomAction('Déchargement de la Page'));
  }, []);

  return {
    onContractClick,
  };
};

export default useApprovedPayment;
