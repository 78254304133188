import { EventAnalytic } from '@sweb-front/types';
import { ADOBELOADED, ADOBELOADEDVALUE } from '@vat/configuration';
import { useEffect, useState } from 'react';

/**
 * This is the solution for allowing the hit to execute in the good way
 * @param event
 * If it is working on the response page, we have to do the same for all the rest of pages
 * but only for the tracker when loading the page not the CTA
 */

const useWebAnalayseTracker = (event: EventAnalytic) => {
  const script = document.getElementById('adobeScript');
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const isAdobeScriptloaded = localStorage.getItem(ADOBELOADED);
  if (script) {
    // useful if partially reload for browser
    script.onload = () => {
      console.log('Adobe launch is loaded in cmp');
      setIsScriptLoaded(true);
    };
  }
  useEffect(() => {
    if (isAdobeScriptloaded === ADOBELOADEDVALUE || isScriptLoaded) {
      console.log('DataLayer =>', (window as any).dataLayer);
      console.log('Event => ', event);
      (window as any).dataLayer.push(event);
    }
  }, [isScriptLoaded, localStorage.getItem(ADOBELOADED)]);
};

export default useWebAnalayseTracker;
