import colors from './colors';
import layout from './layout';
import fonts from './fonts';
import * as themes from './themes';

const variables = {
  colors,
  layout,
  fonts,
};

export const getTheme = (company: 'cetelem' | 'cafineo' = 'cetelem') => {
  return {
    variables,
    vendor: themes[company],
  };
};
