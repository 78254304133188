import Description from '../Description';
import Title from '../Title';
import {
  RedirectionComponentContainer,
  RedirectionComponentContent,
  ProgressBarComponent,
} from './styles';
import { Trans } from 'react-i18next';
import useRedirectionComponent from './useRedirectionComponent';

export interface IRedirectionComponentProps {
  id?: string;
  className?: string;
  delay: number;
  title: string;
  message: string;
  barColor?: string;
  currentPage: string;
  isErrorOccuredInPage?: boolean;
}

const RedirectionComponent = ({
  delay,
  barColor,
  message,
  title,
  id,
  className,
  currentPage,
  isErrorOccuredInPage,
}: IRedirectionComponentProps) => {
  const { progressPercentage, onClickShortcutRedirection } =
    useRedirectionComponent(delay, isErrorOccuredInPage, currentPage);
  return (
    <RedirectionComponentContainer id={id} className={className}>
      <RedirectionComponentContent>
        <Title level={3} className="title">
          {title}
        </Title>
        <Description className="message">{message}</Description>
      </RedirectionComponentContent>
      <ProgressBarComponent progress={progressPercentage}>
        <div></div>
      </ProgressBarComponent>
      <Description className="clickhere">
        <Trans
          i18nKey="common.clickhere"
          components={{
            click: <span onClick={onClickShortcutRedirection} />,
          }}
        />
      </Description>
    </RedirectionComponentContainer>
  );
};

export default RedirectionComponent;
