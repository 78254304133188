import { Description } from '@sweb-front/components';
import { DESKTOP, TAB } from '@sweb-front/utils';
import { StyledValidateButtonWrapper } from '@vat/utils';
import styled from 'styled-components';

export const DescriptionWrapper = styled(Description)`
  margin-bottom: 1.6rem;
  padding: 0 1.6rem;
`;

export const LogoMainWrapper = styled.div`
  display: flex;
  border-radius: 0.8rem;
  box-shadow: 0.2rem 0.8rem 1.6rem -0.2rem var(--neutrals-100);
  justify-content: center;
  align-items: center;
  vertical-align: center;
  height: 15.2rem;
  margin: 3.2rem 0 4rem 0;
  padding: 0.8rem 0;

  & img {
    width: 12.8rem;
  }

  @media screen and ${DESKTOP} {
    box-shadow: none;
  }
`;

export const LogoWrapper = styled.div`
  background-image: url('./assets/images/person-check.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
`;

export const ActionButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and ${TAB}, ${DESKTOP} {
    justify-content: center;
    text-align: center;
    align-items: center;
  }

  &.liveness-button-wrapper {
    margin-bottom: 1.6rem;
  }

  &.modal-button-actions {
    padding: 0 1.6rem;
    margin-top: 1.6rem;
  }
`;

export const ButtonWrapper = styled(StyledValidateButtonWrapper)`
  height: 5.6rem;
  font-family: 'Open Sans Bold';
  width: 100%;
  @media screen and ${TAB}, ${DESKTOP} {
    flex: inherit;
    width: auto;
    min-width: 27rem;
    padding: 0 1.6rem;
  }
`;

export const NonButtonWrapper = styled.div`
  display: flex;
  font-family: 'Open Sans';
  margin-top: 2.4rem;
  color: ${({ theme }) => theme.vendor.colors.secondary};
  font-size: 1.2rem;
  line-height: 2rem;
  cursor: pointer;
  width: 20rem;
  text-align: center;
  align-self: center;
  justify-content: center;
  &:hover {
    text-decoration: underline;
  }
`;
