import { BaseSpinner, Section } from '@sweb-front/components';
import { SpinnerLabel, SpinnerLongText } from '@vat/layouts';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useManagingExternalApp } from '@vat/utils';
import { LoadingWrapper } from '@sweb-front/styles';

const InitOnbo = () => {
  const { t } = useTranslation();
  const { waitingPageBeforeOnbo } = useManagingExternalApp();

  useEffect(() => {
    waitingPageBeforeOnbo();
  }, []);

  return (
    <Section>
      <LoadingWrapper>
        <BaseSpinner size="large" />
        <SpinnerLabel>{t('waitingPage.label')}</SpinnerLabel>
        <SpinnerLongText>{t('waitingPage.longText')}</SpinnerLongText>
      </LoadingWrapper>
    </Section>
  );
};

export default InitOnbo;
