export const cetelem = {
  logo: 'cetelem',
  colors: {
    primary: '#3A913F',
    secondary: 'var(--main-green-500)', // progressbar, check sur les boutons, bg button, accès site marchand, checkbox kyc, loading
    title: 'var(--neutrals-700)',
    light: 'var(--green-50)',
    lightest: '#F5F9F6',
    hover: '#3A913F',
    fieldtext: 'var(--medium-grey)',
  },
  colorsDemat: {
    breadCrumbs: '#3A913F',
    titlesText: '#3A913F',
    nextButton: '#3A913F',
    elementsColor: '#3A913F',
    backGroundColor: '#E8F5E9',
    logosColor: '#3A913F',
    fieldtext: 'var(--medium-grey)',
  },
};

export const cafineo = {
  logo: 'cafineo',
  colors: {
    primary: '#134267', // when click on button
    secondary: '#115587', // progressbar, check sur les boutons, bg button, accès site marchand, checkbox kyc, loading
    title: 'var(--neutrals-700)', // each page title color
    light: '#E2F0FC', // civility background when selected
    lightest: '#E5F5FD',
    hover: '#1073B9',
  },
};
