import styled from 'styled-components';

export const CheckboxContainer = styled.div`
  display: flex;
  column-gap: 1rem;
  font-size: 1.4rem;
  line-height: 2rem;
  font-family: 'Open Sans';
  letter-spacing: 0;
  color: var(--neutrals-500);
`;

export const StyledCheckboxInput = styled.input`
  margin-top: 0.3rem;
  color: var(--neutrals-500);
  width: 2rem;
  height: 2rem;
  accent-color: ${({ theme }) => theme.vendor.colors.secondary};
`;

export const StyledCheckboxLabel = styled.label`
  flex: 1;
  justify-content: top;
  color: var(--neutrals-500);
  cursor: pointer;
  margin-top: 0.2rem;
`;
