import { TAB } from '@sweb-front/utils';
import styled from 'styled-components';

export const StyledValidateButtonWrapper = styled.button<{
  $isDisabled?: boolean;
}>`
  color: var(--neutrals-0);
  border: none;
  border-radius: 4px;
  font-family: Open Sans Bold;
  font-size: 18px;
  line-size: 26px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  margin: 0;
  padding: 1rem 1.5rem 1rem 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-grow: 0;
  height: 5.6rem; /* 56px */
  cursor: ${(props) => (props.$isDisabled ? 'not-allowed' : 'pointer')};
  background-color: ${(props) =>
    props.$isDisabled
      ? 'var(--neutrals-300)'
      : `${props.theme.vendor.colors.secondary}`};
  @media screen and ${TAB} {
    width: 11.6rem; /* 116px */
  }

  &:hover {
    background-color: ${(props) =>
      props.$isDisabled
        ? 'var(--neutrals-300)'
        : `${props.theme.vendor.colors.primary}`};
  }
`;

export const StyledBackButtonWrapper = styled.div`
  height: 5.6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
`;

export const ButtonActionWrapper = styled.div`
  margin-top: 1.5rem;
  @media screen and ${TAB} {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin: 4rem 0;
    & .back-button-wrapper {
      margin: 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }
`;

export const IncomeDetailsInfoWrapper = styled.div`
  margin: 0;
  margin-top: 0.8rem;
`;

export const BaseMonthIncomeNumberWrapper = styled.div`
  margin: 2.8rem 0 2.4rem 0;
  display: flex-row;
  flex-direction: row;

  & selected-button {
    margin-right: 0.4rem;
    font-family: 'Open Sans';
    font-size: 1.6rem;
    flex-grow: 1;
    min-width: 3.2rem;
    padding: 1.7rem 0;
    color: var(--neutrals-500);
  }

  & selected-button:last-of-type {
    margin-right: 0;
  }
`;
export const IncomeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1.6rem 0;
  & numeric-input {
    flex-grow: 1;
  }
`;
