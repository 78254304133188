import { createContext, useEffect } from 'react';
import type { EventParams } from '@sweb-front/types';
import { useAdobeTagManager } from '@sweb-front/hooks';

export interface AdobeTagManagerProps {
  children: React.ReactNode;
  LOGGER?: Record<string, (...args: unknown[]) => void> | Console;
}

export type IContext = (event: EventParams) => void;

export const Context = createContext<IContext>(
  // eslint-disable-next-line no-void
  () => void 0
);

const AdobeTagManager = ({
  children,
  LOGGER = console,
}: AdobeTagManagerProps): React.ReactElement => {
  const { trackEvent } = useAdobeTagManager({ LOGGER });

  useEffect(() => {
    try {
      // Init the tag manager and dataLayer
      window.dataLayer = [
        { 'gtm.start': new Date().getTime(), event: 'gtm.js' },
      ];
      // Init the google tag manager script
      const googletagmanagerScript = document.createElement('script');
      googletagmanagerScript.async = true;
      googletagmanagerScript.src = `//www.googletagmanager.com/gtm.js?id=GTM-K5WNH84`;
      document.head.appendChild(googletagmanagerScript);
      console.log('google tag manager added in head !! \n');

      return () => {
        document.head.removeChild(googletagmanagerScript);
      };
    } catch (error) {
      console.log('error on loading googletagmanager ', error);
      LOGGER.error(error);
    }
    return () => {};
  }, []);

  return <Context.Provider value={trackEvent}>{children}</Context.Provider>;
};

export default AdobeTagManager;
