import { BaseSpinner, Section } from '@sweb-front/components';
import { SpinnerLabel, SpinnerLongText } from '@vat/layouts';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useManagingExternalApp } from '@vat/utils';
import { LoadingWrapper } from '@sweb-front/styles';
import { usePageRedirection } from '@sweb-front/hooks';
import { OPENBANKING } from '@vat/configuration';

const OpenBanking = () => {
  const { t } = useTranslation();
  const { waitingPageBeforeOpenBanking } = useManagingExternalApp();
  const { isPageLoading } = usePageRedirection(OPENBANKING);

  useEffect(() => {
    if (isPageLoading) {
      return;
    }
    waitingPageBeforeOpenBanking();
  }, [isPageLoading]);

  return (
    !isPageLoading && (
      <Section>
        <LoadingWrapper>
          <BaseSpinner size="large" />
          <SpinnerLabel>{t('waitingPage.label')}</SpinnerLabel>
          <SpinnerLongText>{t('waitingPage.longText')}</SpinnerLongText>
        </LoadingWrapper>
      </Section>
    )
  );
};

export default OpenBanking;
