import { useTranslation } from 'react-i18next';

import {
  ContentWrapper,
  SectionWrapper,
  SVGIconWrapper,
  TitleCmp,
  Paragraph,
  BoldParagraph,
} from './styles';
import { useAppSelector } from '@sweb-front/store';
import useRefusedPayment from './useRefusedPayment';

const RefusedPayment = () => {
  const { t } = useTranslation();
  const parameters = useAppSelector((state) => state.parameters.state);
  const opportunity = useAppSelector((state) => state.opportunity.state);
  useRefusedPayment(opportunity, parameters);
  return (
    <SectionWrapper>
      <SVGIconWrapper>
        <base-icon icon="cross circle" />
      </SVGIconWrapper>
      <ContentWrapper>
        <TitleCmp>{t('responses.refused.title')}</TitleCmp>
        <Paragraph>{t('responses.refused.gratitude')}</Paragraph>
        <Paragraph>{t('responses.refused.insufficientRessources')}</Paragraph>
        <Paragraph> {t('responses.refused.otherPaymentWay')}</Paragraph>
        <BoldParagraph>
          {t('responses.refused.securedBankInformations')}
        </BoldParagraph>
      </ContentWrapper>
    </SectionWrapper>
  );
};

export default RefusedPayment;
