import { IEngineContextConfig } from './useEngineLoader';
const config = {
  data: [
    {
      setting: ['common'],
      theme: 'cetelem',
      value: {
        legalNotices: {},
        insurance: {
          aerasConvention: '08 20 22 61 02',
        },
        chart: 'green',
        footer: {},
        logo: {
          src: 'logos/cetelm.svg',
          width: '130',
        },
        response: {
          logo: {
            src: '/images/cetelem-success.png',
            width: '152',
            height: '160',
          },
        },
      },
    },
    {
      setting: ['100'],
      theme: 'cetelem',
      value: {
        company: 'BNP Paribas Personal Finance',
        insurance: {
          aerasConvention: '08 20 22 61 02',
          warrantyDetails: {
            explication:
              '(1) Les garanties s’exécutent dans les conditions et limites de garantie disponible sur la Notice.',
            assuranceExplication1:
              'Pour toute adhésion à l’Assurance Emprunteur facultative souscrite auprès de CARDIF Assurance Vie et CARDIF Assurances Risques Divers :',
            assuranceExplication2:
              "- Décès, Perte Temporaire Totale et Irréversible d'Autonomie, Incapacité Temporaire Totale de Travail, Perte d’Emploi à la suite d'un licenciement (jusqu'à 64 ans).",
            assuranceExplication3:
              'L’Assurance Emprunteur est un contrat d’assurance souscrit par BNP Paribas Personal Finance auprès de Cardif Assurance Vie – S.A. au capital de 719 167 488 euros – 732 028 154 R.C.S. Paris - Siège social : 1, boulevard Haussmann – 75009 Paris et de Cardif Assurances Risques Divers – S.A. au capital de 21 602 240 euros – 308 896 547 R.C.S. Paris – Siège social : 1, boulevard Haussmann – 75009 Paris, entreprises régies par le code des assurances.',
          },
          documents: {
            notice: 'ADE-VAT-Distribution-2822-728.pdf',
            ficheConseil: 'FICA-VAT-Distribution-2822-728.pdf',
            docInformation: 'IPID-ADE-CONSO-PF-ADE-VAT-DISTRIBUTION-202102.pdf',
          },
        },
        legalNotices: {},
        footer: {
          contact: [
            {
              address:
                'BNP Paribas Personal Finance - Service consommateurs - 95908 Cergy Pontoise Cedex 09 : ',
              mail: 'consommateur.donneespersonnelles@bnpparibas-pf.com',
            },
          ],
          personalDataProtectionLink:
            'https://asset.mediahub.bnpparibas/is/content/bnpparibas/DPN%20V3%20PF%20sans%20option%20clause%2008042022%20PF%2017052022',
        },
        logo: {
          src: 'logos/cetelm.svg',
          width: '130',
        },
        response: {
          logo: {
            src: '/images/cetelem-success.png',
            width: '152',
            height: '160',
          },
        },
      },
    },
    {
      setting: ['140'],
      theme: 'cetelem',
      value: {
        company: 'BNP Paribas Personal Finance',
        insurance: {
          aerasConvention: '08 20 22 61 02',
          warrantyDetails: {
            explication:
              '(1) Les garanties s’exécutent dans les conditions et limites de garantie disponible sur la Notice.',
            assuranceExplication1:
              'Pour toute adhésion à l’Assurance Emprunteur facultative souscrite auprès de CARDIF Assurance Vie et CARDIF Assurances Risques Divers :',
            assuranceExplication2:
              "- Décès, Perte Temporaire Totale et Irréversible d'Autonomie, Incapacité Temporaire Totale de Travail, Perte d’Emploi à la suite d'un licenciement (jusqu'à 64 ans).",
            assuranceExplication3:
              'L’Assurance Emprunteur est un contrat d’assurance souscrit par BNP Paribas Personal Finance auprès de Cardif Assurance Vie – S.A. au capital de 719 167 488 euros – 732 028 154 R.C.S. Paris - Siège social : 1, boulevard Haussmann – 75009 Paris et de Cardif Assurances Risques Divers – S.A. au capital de 21 602 240 euros – 308 896 547 R.C.S. Paris – Siège social : 1, boulevard Haussmann – 75009 Paris, entreprises régies par le code des assurances.',
          },
          documents: {
            notice: 'ADE-VAT-Distribution-2822-728.pdf',
            ficheConseil: 'FICA-VAT-Distribution-2822-728.pdf',
            docInformation: 'IPID-ADE-CONSO-PF-ADE-VAT-DISTRIBUTION-202102.pdf',
          },
        },
        legalNotices: {},
        footer: {
          contact: [
            {
              address:
                'BNP Paribas Personal Finance - Service consommateurs - 95908 Cergy Pontoise Cedex 09 : ',
              mail: 'consommateur.donneespersonnelles@bnpparibas-pf.com',
            },
          ],
          personalDataProtectionLink:
            'https://asset.mediahub.bnpparibas/is/content/bnpparibas/DPN%20V3%20PF%20sans%20option%20clause%2008042022%20PF%2017052022',
        },
        logo: {
          src: 'logos/cetelm.svg',
          width: '130',
        },
        response: {
          logo: {
            src: '/images/cetelem-success.png',
            width: '152',
            height: '160',
          },
        },
      },
    },
    {
      setting: ['133', '134'],
      theme: 'cetelem',
      value: {
        company: 'Crédit Moderne Antilles Guyane',
        insurance: {
          aerasConvention: '08 20 22 61 02',
          warrantyDetails: {
            explication:
              '(1) Les garanties s’exécutent dans les conditions et limites de garantie disponible sur la Notice.',
            assuranceExplication1:
              'Pour toute adhésion à l’Assurance Emprunteur facultative souscrite auprès de CARDIF Assurance Vie et CARDIF Assurances Risques Divers :',
            assuranceExplication2:
              "- Décès, Perte Temporaire Totale et Irréversible d'Autonomie, Incapacité Temporaire Totale de Travail, Perte d’Emploi à la suite d'un licenciement (jusqu'à 64 ans).",
            assuranceExplication3:
              'L’Assurance Emprunteur est un contrat d’assurance souscrit par Crédit moderne SA auprès de Cardif Assurance Vie – S.A. au capital de 719 167 488 euros – 732 028 154 R.C.S. Paris - Siège social : 1, boulevard Haussmann - 75009 Paris - N° ADEME : FR200182_01XHWE pour les risques Décès et Perte Totale et Irréversible d’Autonomie et CARDIF-Assurances Risques Divers - S. A. au capital de 21 602 240,00 euros - 308 896 547 R.C.S Paris - Siège social : 1, boulevard Haussmann - 75009 Paris - N° ADEME : FR200182_01XHWE pour le risque Incapacité Temporaire Totale de travail, entreprises régies par le code des assurances.',
          },
          documents: {
            notice: 'ADE-VAT-Distribution-2822-728.pdf',
            ficheConseil: 'FICA-VAT-Distribution-2822-728.pdf',
            docInformation: 'IPID-ADE-CONSO-PF-ADE-VAT-DISTRIBUTION-202102.pdf',
          },
        },
        legalNotices: {
          capitalAmount: '18 727 232',
          entityPlace:
            'Immeuble Le Semaphore - rue René Rabat ZAC de Houëlbourg Sud II Z.I. de Jarry - 97122 Baie-Mahault Cedex.',
          oriasNumber: '07 027 944',
          phoneNumber: '09 69 32 25 78',
          publishedAuthor: 'Laurent CLAUDEL',
          rcsNumber: 'RCS Pointe à Pitre n°341 891 653.',
          socialEntityName: 'Crédit Moderne Antilles Guyane',
          prudentialEntity:
            'ACPR - 4 Place de Budapest, CS 92459, 75436 Paris Cedex 09',
        },
        footer: {
          contact: [
            {
              address:
                'Crédit Moderne Antilles Guyane- Service consommateurs - ZI les Mangles Acajou - 97232 Lamentin : ',
              mail: 'cmagconsommateur.donneespersonnelles@credit-moderne.fr',
            },
          ],
          personalDataProtectionLink:
            'https://asset.mediahub.bnpparibas/is/content/bnpparibas/DPN%20V3%20PF%20sans%20option%20clause%2008042022%20CMAG%2017052022',
        },
        logo: {
          src: 'logos/credit-moderne.svg',
          width: '220',
        },
        response: {
          logo: {
            src: '/images/cetelem-success.png',
            width: '152',
            height: '160',
          },
        },
      },
    },
    {
      setting: ['236'],
      theme: 'cetelem',
      value: {
        company: 'Crédit Moderne Océan Indien',
        insurance: {
          aerasConvention: '08 20 22 61 02',
          warrantyDetails: {
            explication:
              '(1) Les garanties s’exécutent dans les conditions et limites de garantie disponible sur la Notice.',
            assuranceExplication1:
              'Pour toute adhésion à l’Assurance Emprunteur facultative souscrite auprès de CARDIF Assurance Vie et CARDIF Assurances Risques Divers :',
            assuranceExplication2:
              "- Décès, Perte Temporaire Totale et Irréversible d'Autonomie, Incapacité Temporaire Totale de Travail, Perte d’Emploi à la suite d'un licenciement (jusqu'à 64 ans).",
            assuranceExplication3:
              'L’Assurance Emprunteur est un contrat d’assurance souscrit par Crédit moderne SA auprès de Cardif Assurance Vie – S.A. au capital de 719 167 488 euros – 732 028 154 R.C.S. Paris - Siège social : 1, boulevard Haussmann - 75009 Paris - N° ADEME : FR200182_01XHWE pour les risques Décès et Perte Totale et Irréversible d’Autonomie et CARDIF-Assurances Risques Divers - S. A. au capital de 21 602 240,00 euros - 308 896 547 R.C.S Paris - Siège social : 1, boulevard Haussmann - 75009 Paris - N° ADEME : FR200182_01XHWE pour le risque Incapacité Temporaire Totale de travail, entreprises régies par le code des assurances.',
          },
          documents: {
            notice: 'Notice-ADE-VAT-CMOI-v202310.pdf',
            ficheConseil: 'Fiche-conseil-VAT-CMOI-v202310.pdf',
            docInformation: 'IPID-ADE-VAT-CMOI-v202310.pdf',
          },
        },
        legalNotices: {
          capitalAmount: '4 575 776',
          entityPlace: '22 rue Pierre Aubert 97495 Sainte Clotilde Cedex.',
          oriasNumber: '07 029 039',
          phoneNumber: '02 0',
          publishedAuthor: 'Najib GHARIB',
          rcsNumber: 'RCS Saint Denis n°333 353 944.',
          socialEntityName: 'Crédit Moderne Océan Indien.',
          prudentialEntity:
            'ACPR - 4 Place de Budapest, CS 92459, 75436 Paris Cedex 09',
        },
        footer: {
          contact: [
            {
              address:
                'Crédit Moderne Océan Indien - Service consommateurs - 28 rue Lislet Geoffroy CS 91028 – 97495 Ste Clotilde : ',
              mail: 'cmoiconsommateur.donneespersonnelles@credit-moderne.fr',
            },
          ],
          personalDataProtectionLink:
            'https://asset.mediahub.bnpparibas/is/content/bnpparibas/DPN%20V3%20PF%20sans%20option%20clause%2008042022%20CMOI%2017052022',
        },
        logo: {
          src: 'logos/credit-moderne.svg',
          width: '220',
        },
        response: {
          logo: {
            src: '/images/cetelem-success.png',
            width: '152',
            height: '160',
          },
        },
      },
    },
    {
      setting: ['237', '238', '239', '240'],
      theme: 'cafineo',
      value: {
        company: 'Cafineo',
        legalNotices: {
          capitalAmount: '8 295 000',
          entityPlace:
            'Immeuble Le Semaphore - rue René Rabat ZAC de Houëlbourg Sud II Z.I. de Jarry - 97122 Baie-Mahault',
          oriasNumber: '08 042 946',
          phoneNumber: '09 69 32 25 79',
          publishedAuthor: 'Ghislain Duville',
          rcsNumber: 'RCS Pointe à Pitre n°501 103 337',
          socialEntityName: 'Cafineo',
          prudentialEntity: 'ACP - 4 Place de Budapest - 75436 Paris Cedex 09',
        },
        insurance: {
          aerasConvention: '08 20 22 61 02',
          warrantyDetails: {
            explication:
              '(1) Les garanties s’exécutent dans les conditions et limites de garantie disponibles sur la Notice.',
            assuranceExplication1:
              'Pour toute adhésion à l’Assurance Emprunteur facultative souscrite auprès de CARDIF Assurance Vie et CARDIF Assurances Risques Divers :',
            assuranceExplication2:
              "- Décès (jusqu’à 80 ans), Perte Totale et Irréversible d'Autonomie, Incapacité Temporaire Totale de Travail (jusqu'à 65 ans).",
            assuranceExplication3:
              'L’Assurance Emprunteur est un contrat d’assurance souscrit par Cafineo SA auprès de Cardif Assurance Vie – S.A. au capital de 719 167 488 euros – 732 028 154 R.C.S. Paris - Siège social : 1, boulevard Haussmann - 75009 Paris - N° ADEME : FR200182_01XHWE pour les risques Décès et Perte Totale et Irréversible d’Autonomie et CARDIF-Assurances Risques Divers - S. A. au capital de 21 602 240,00 euros - 308 896 547 R.C.S Paris - Siège social : 1, boulevard Haussmann - 75009 Paris - N° ADEME : FR200182_01XHWE pour le risque Incapacité Temporaire Totale de travail, entreprises régies par le code des assurances.',
          },
          documents: {
            notice: 'Notice-ADE-VAT-CAFINEO-OI-v202310.pdf',
            ficheConseil: 'Fiche-conseil-VAT-CAFINEO-OI-v202310.pdf',
            docInformation: 'IPID-ADE-VAT-CAFINEO-OI-v202310.pdf',
          },
        },
        footer: {
          contact: [
            {
              address:
                'Antilles Guyane : Service consommateurs - ZI Les Mangles Acajou – 97232 Le Lamentin : ',
              mail: 'cmagconsommateur.donneespersonnelles@cafineo.fr',
            },
            {
              address:
                'Océan Indien : Service Consommateurs – 28, rue Lislet Geoffroy - CS 91028 - 97495 Sainte Clotilde : ',
              mail: 'cmoiconsommateur.donneespersonnelles@cafineo.fr',
            },
          ],
          personalDataProtectionLink:
            'https://asset.mediahub.bnpparibas/is/content/bnpparibas/DPN%20V3%20PF%20sans%20option%20clause%2008042022%20CAFINEO%20Antilles%20Guyanne%20et%20R%C3%A9union%2017052022-1',
        },
        logo: {
          src: 'logos/cafineo.svg',
          width: '130',
        },
        response: {
          logo: {
            src: '/logos/cafineo-short.svg',
            width: '122',
            height: '128',
            style: {
              marginRight: '3.8rem',
            },
          },
        },
        chart: 'blue',
      },
    },
  ],
} as IEngineContextConfig;

export default config;
