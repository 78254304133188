import { t } from 'i18next';
import { useMemo } from 'react';
import {
  IOfferDetailCard,
  IOfferDetailCardContent,
  IOptionsCost,
  IOptionsDocument,
} from '../GenericInsuranceContainer';
import { getDataBySetting, useEngineLoader } from '@vat/utils';
import config from 'src/utils/hooks/engineContext';

export interface IPrice {
  monthlyCost: string;
  totalCost: string;
  yearlyInsuranceAmount: string;
}

export interface IConstantsProps {
  excludeOffer?: string[];
  prices: IPrice;
}

const useConstants = (
  prices: IPrice,
  isRecommendedOffer: boolean,
  excludeOffer = [] as string[]
) => {
  const companyCd = useEngineLoader();
  const companyData = getDataBySetting(config, companyCd);
  const docAssurance = companyData.value.insurance.documents as Record<
    string,
    string
  >;
  const contents = useMemo<IOfferDetailCardContent[]>(
    () =>
      [
        {
          title: t('insurance.details.death.title'),
          description: t('insurance.details.death.explanation'),
          insuranceKey: 'D',
        },
        {
          title: t('insurance.details.invalidity.title'),
          description: t('insurance.details.invalidity.explanation'),
          insuranceKey: 'DIM',
        },
        {
          title: t('insurance.details.illnessOrAccident.title'),
          description: t('insurance.details.illnessOrAccident.explanation'),
          insuranceKey: 'DIM',
        },
        {
          title: t('insurance.details.unemployment.title'),
          description: t('insurance.details.unemployment.explanation'),
          insuranceKey: 'DIMC',
        },
      ].filter((content) => !excludeOffer.includes(content.insuranceKey)),
    []
  );

  const offerDetails = useMemo<IOfferDetailCard>(
    () => ({
      bannerTitle: isRecommendedOffer
        ? t('insurance.form.recommendedOffre')
        : undefined,
      cardDescription: t('insurance.details.description'),
      contents,
    }),
    [t]
  );

  const options = useMemo<IOptionsCost>(
    () => ({
      title: t('insurance.details.facultativeInsuranceCost'),
      options: [
        {
          label: t('insurance.details.monthlyCost'),
          price: prices.monthlyCost,
          unit: ' € / mois',
          priceKey: 'monthlyCost',
        },
        {
          label: t('insurance.details.totalCost'),
          price: prices.totalCost,
          unit: ' €',
          priceKey: 'totalCost',
        },
        {
          label: t('insurance.details.YearlyInsuranceAmount'),
          price: prices.yearlyInsuranceAmount,
          unit: ' %',
          longRowItem: true,
          priceKey: 'YearlyInsuranceAmount',
        },
      ],
    }),
    [t]
  );

  const documents = useMemo<IOptionsDocument>(
    () => ({
      title: t('insurance.details.documentWithinOptionalInsurance'),
      documents: [
        {
          label: 'Document d’information',
          link: docAssurance.docInformation,
          fileName: 'document infos assurance.pdf',
        },
        {
          label: 'Fiche conseil',
          link: docAssurance.ficheConseil,
          fileName: 'fiche conseil.pdf',
        },
        {
          label: 'Notice',
          link: docAssurance.notice,
          fileName: 'notice assurance.pdf',
        },
      ],
    }),
    [t, docAssurance]
  );

  return {
    documents,
    options,
    offerDetails,
  };
};

export default useConstants;
