import { BoldSpan, TextBlock, TextLine, Title } from './styles';
export default function MentionsLegales140() {
  return (
    <>
      <TextBlock>
        <TextLine>
          <BoldSpan>Raison sociale et siège social :</BoldSpan>
        </TextLine>
        <TextLine>
          <BoldSpan>Editeur: </BoldSpan>BNP Paribas Personal Finance au capital
          de 561 882 202 €
        </TextLine>
        <TextLine>
          <BoldSpan>SIREN: </BoldSpan> 542 097 902 RCS Paris
        </TextLine>
        <TextLine>
          <BoldSpan>Siège social : </BoldSpan> 1 Boulevard Haussmann - 75009
          Paris France
        </TextLine>
        <TextLine>
          <BoldSpan>N° ORIAS :</BoldSpan> 07 023 128 (
          <a
            href="https://www.orias.fr"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.orias.fr
          </a>
          )
        </TextLine>
        <TextLine>
          <BoldSpan>N° ADEME :</BoldSpan> FR200182_01XHWE.
        </TextLine>
        <TextLine>
          <BoldSpan>Directrice de la publication : </BoldSpan>Mme Charlotte
          DENNERY
        </TextLine>
        <TextLine>
          <BoldSpan>Intégration et développement : </BoldSpan> CGI Avenue Marcel
          Dassault Parc d&quot;activité Jean Mermoz - Bât. B 34170
          Castelnau-le-Lez
        </TextLine>
        <TextLine>
          <BoldSpan>Hébergeur : </BoldSpan>Prosodie, 150, rue Galliéni - 92100
          Boulogne-Billancourt
        </TextLine>
        <TextLine>
          <BoldSpan>Crédits photos : </BoldSpan> Crédits Visuels – Internet –
          Publitek, Inc., dba Fotosearch –Fotolia - Thinkstock
        </TextLine>
      </TextBlock>
      <Title>Liste des établissements</Title>
      <TextBlock>
        <TextLine>
          Liste des établissements de crédit soumis au secret professionnel
          bancaire en vertu de l&quot;article L 511-33 du Code Monétaire et
          Financier, liés à BNP PARIBAS Personal Finance Sociétés faisant partie
          du Groupe BNP PARIBAS Personal Finance Sociétés totalement gérées :
          <ul>
            <li>COFICA BAIL</li>
            <li>CREDIT MODERNE ANTILLES GUYANE</li>
            <li>CREDIT MODERNE</li>
            <li>OCEAN INDIEN</li>
            <li>DOMOFINANCE</li>
            <li>LOISIRS FINANCE</li>
            <li>NORRSKEN</li>
            <li>FINANCE</li>
            <li>PROJEO</li>
            <li>CAFINEO</li>
          </ul>
        </TextLine>
      </TextBlock>
      <TextBlock>
        <TextLine>
          Sociétés faisant parties du Groupe BNP Paribas ayant confié tout ou
          partie de la gestion de leurs crédits à la consommation à BNP PARIBAS
          Personal Finance :
          <ul>
            <li>BNP Paribas</li>
          </ul>
        </TextLine>
      </TextBlock>
      <TextBlock>
        <TextLine>
          Etablissements de crédit liés à BNP PARIBAS Personal Finance pour la
          gestion de leurs crédits ou des transactions relatives aux opérations
          de paiement, avec ou sans participation capitalistique :
          <ul>
            <li>AXA BANQUE FINANCEMENT</li>
            <li> AXA BANQUE (anciennement Banque Directe)</li>
            <li> NATIXIS FINANCEMENT</li>
            <li> BANQUE REVILLON</li>
          </ul>
        </TextLine>
      </TextBlock>
    </>
  );
}
