import { WarrantiesWrapper } from './styles';
import { getDataBySetting, useEngineLoader } from '@vat/utils';
import config from 'src/utils/hooks/engineContext';

const WarrantiesText = () => {
  const companyCd = useEngineLoader();
  const companyData = getDataBySetting(config, companyCd).value.insurance
    .warrantyDetails as Record<string, string>;

  return (
    <WarrantiesWrapper>
      <p>{companyData.explication}</p>
      <p>
        <span>{companyData.assuranceExplication1}</span>
        <span>{companyData.assuranceExplication2}</span>
      </p>
      <p>{companyData.assuranceExplication3}</p>
    </WarrantiesWrapper>
  );
};

export default WarrantiesText;
