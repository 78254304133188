import { BoldSpan, TextBlock, TextLine, Title } from './styles';

export default function MentionsLegales100() {
  return (
    <>
      <TextBlock>
        <TextLine>
          <BoldSpan>Dénomination sociale :</BoldSpan> BNP Paribas Personal
          Finance
        </TextLine>
        <TextLine>
          <BoldSpan>Société Anonyme au capital de :</BoldSpan> 583 834 454€.
        </TextLine>
        <TextLine>
          <BoldSpan>Siège social :</BoldSpan> 1 Boulevard Haussmann - 75009
          Paris France.
        </TextLine>
        <TextLine>
          <BoldSpan>RCS :</BoldSpan> Paris n° 542 097 902.
        </TextLine>
        <TextLine>
          <BoldSpan>Code TVA :</BoldSpan> FR 60 542097 902.
        </TextLine>
        <TextLine>
          <BoldSpan>N° ORIAS :</BoldSpan> 07 023 128 (
          <a
            href="https://www.orias.fr"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.orias.fr
          </a>
          )
        </TextLine>
        <TextLine>
          <BoldSpan>N° ADEME :</BoldSpan> FR200182_01XHWE.
        </TextLine>
      </TextBlock>
      <Title>Nous contacter</Title>
      <TextBlock>
        <TextLine>
          <BoldSpan>Service Consommateurs :</BoldSpan>
        </TextLine>
        <TextLine>
          <BoldSpan>Crédit à la consommation :</BoldSpan> 95908 Cergy Pontoise
          Cedex 09
        </TextLine>
        <TextLine>
          <BoldSpan>Crédit Immobilier :</BoldSpan> BP 71 015 - 44010 Nantes
          Cedex
        </TextLine>
        <TextLine>
          <BoldSpan>Regroupement de crédits à la consommation :</BoldSpan> 95908
          Cergy Pontoise Cedex 09
        </TextLine>
      </TextBlock>
      <TextBlock>
        <TextLine>
          <BoldSpan>Regroupement de crédits Immobiliers :</BoldSpan> BP 71 015
          44010 Nantes Cedex
        </TextLine>
        <TextLine>
          <BoldSpan>Numéro de téléphone :</BoldSpan> 09 69 32 75 02 service
          gratuit + prix appel
        </TextLine>
        <TextLine>
          <BoldSpan>Directrice de la publication :</BoldSpan> Mme Charlotte
          DENNERY
        </TextLine>
        <TextLine>
          Ce site est hébergé sur les serveurs de BNP Paribas Personal Finance.{' '}
          <br />
          BNP Paribas Personal Finance est un établissement de crédit de droit
          français agréé en qualité de banque par l’Autorité de Contrôle
          Prudentiel et de Résolution (ACPR - 4 Place de Budapest, CS 92459,
          75436 Paris Cedex 09) et contrôlé par cette même autorité.
        </TextLine>
      </TextBlock>
      <TextBlock>
        <TextLine>
          BNP Paribas Personal Finance exerce ses activités conformément aux
          dispositions des articles L. 311-1 et suivants du Code monétaire et
          financier, aux dispositions des articles L. 511-1 et suivants du même
          Code et aux règlements applicables.
        </TextLine>
      </TextBlock>
      <TextBlock>
        <TextLine>
          BNP Paribas Personal Finance est membre de l&apos;Association
          Française des Sociétés Financières et de la Fédération Bancaire
          Française.
        </TextLine>
      </TextBlock>
      <TextBlock>
        <TextLine>
          Pour toute information sur les offres Cetelem proposées sur le site
          vous pouvez contacter le 09 69 32 75 02 (Service gratuit + coût de
          l’appel), ou, pour l’île de la Réunion contactez-le Crédit moderne au
          02 62 92 04 04 (coût d&apos;un appel local depuis un fixe), pour la
          Guadeloupe, la Guyane ou la Martinique, contactez-le Crédit Moderne au
          05 90 32 60 62 (coût d&apos;un appel local depuis un fixe). Enfin,
          vous pouvez également faire une demande de prêt directement sur le
          site{' '}
          <a
            href="https://www.credit-moderne.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.credit-moderne.com
          </a>
          . Ce site fournit également des informations sur les produits et
          services offerts par les sociétés du groupe BNP Paribas Personal
          Finance dont : - Cofica Bail S.A. au capital de 14 485 544 euros 399
          181 924 RCS Paris, Siège social : 1 boulevard Haussmann 75009 Paris
          N°ORIAS : 07 023 197 (
          <a
            href="https://www.orias.fr"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.orias.fr
          </a>
          )
        </TextLine>
        <TextLine>
          Société de courtage d’assurances non soumise à l’exclusivité : liste
          des entreprises d’assurances partenaires disponibles sur simple
          demande. Sociétés soumises à l’Autorité de Contrôle Prudentiel et de
          Résolution 4 Place de Budapest, CS 92459, 75436 Paris Cedex 09. ARVAL
          Service Lease - Société Anonyme au capital de 66 412 800 € - 352 256
          424 RCS Paris Siège social : 1, boulevard Haussmann - 75009 Paris –
          Identifiant CE FR 68 352 256 424 - ORIAS n° 07 022 411.
        </TextLine>
      </TextBlock>
    </>
  );
}
