import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IInsurance } from '@sweb-front/types';

import { IState } from './assuranceTypes';

export interface IInsuranceFiles {
  label: string;
  link: string;
  fileName: string;
}

const getInitialState = () => {
  return {
    state: {
      assuranceData: [],
    },
  } as { state: IState };
};

export const assuranceSlice = createSlice({
  name: 'assurance',
  initialState: getInitialState(),
  reducers: {
    clear(assuranceState) {
      assuranceState.state = getInitialState().state;
    },
    setAssurance(assuranceState, dataAssurance: PayloadAction<IInsurance[]>) {
      assuranceState.state.assuranceData = dataAssurance.payload;
      const letters: string[] = [];
      dataAssurance?.payload?.forEach(
        (data) => data?.optionCd && letters.push(...data.optionCd.split(''))
      );
      assuranceState.state.astrickLetters = [
        ...new Set(letters.map((item) => item)),
      ];
    },
    setInsuranceDocuments(
      assuranceState,
      dataAssurance: PayloadAction<IInsuranceFiles[]>
    ) {
      assuranceState.state.files = dataAssurance.payload;
    },
  },
});
export const { clear, setAssurance, setInsuranceDocuments } =
  assuranceSlice.actions;
export default assuranceSlice.reducer;
